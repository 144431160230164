<template>
  <div>
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <div
          class="d-flex align-self-center justify-center align-center mt-md-5"
        >
          <img
            width="40%"
            src="../../assets/conteudos_casas_title_08.png"
            alt=""
          />
        </div>
      </v-row>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  As comunidades de Mariana herdaram dos seus antepassados as
                  casas de pau a pique, estruturadas com esteios de madeira. Os
                  esteios são travados no topo por peças também de madeira
                  chamadas frechais, vigas que vão ficar no alto da parede,
                  recebendo as peças do telhado. Os esteios são apoiados no
                  alicerce, onde é feita uma cavidade para se encaixar a base da
                  coluna. A viga baldrame (alicerce) geralmente é de pedra ou de
                  madeira. No caso de construções com mais de um pavimento, é
                  posicionada a viga na altura intermediária, ou seja, do piso
                  que divide os pavimentos. Essa peça também de madeira é
                  chamada de madre e faz parte da estrutura de madeira conhecida
                  como “gaiola”.
                </p>
                <p>
                  Da mesma forma que ocorreu com as fundações, as casas novas
                  passaram a ser construídas com pilares e vigas de concreto
                  armado (massa de água, cimento e agregados — pedra e areia — e
                  armação feita com barras de aço). Em outros casos, com a
                  chegada dos tijolos maciços e depois dos blocos de concreto,
                  as alvenarias além de terem a função de fechamento, suportam o
                  peso do telhado.
                </p>
                <p>
                  Mesmo com o uso dos novos materiais e modos de executar mais
                  recentes, a estrutura de madeira continuou a fazer parte das
                  construções, seja em toda a casa ou em parte dos seus cômodos.
                  Da mesma forma, as construções de uso rural continuam
                  utilizando pilares e vigas de madeira, principalmente por
                  serem encontradas na região ou no próprio terreno. No caso de
                  construções pequenas e mais leves, muitas vezes as colunas são
                  fincadas no próprio solo, dispensando o alicerce. Exemplo
                  disso são os galinheiros, coberturas do fogão a lenha e
                  paióis. No caso desses últimos, é feito um assoalho distante
                  do solo para evitar que os grãos fiquem em contato com a
                  umidade. As tábuas do piso são instaladas sobre peças de
                  madeira horizontais, encaixadas nas colunas.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-start">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_41.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Casa arruinada em um terreno de Pedras, construída com estrutura de
            madeira. Foto: Vistoria técnica da Cáritas, 2019.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_42.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Casarão arruinado em terreno de Ponte do Gama, construído com
            estrutura de madeira. Foto: Vistoria técnica da Cáritas, 2018.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_43.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Casa construída com alvenarias autoportantes de tijolos maciços na
            comunidade de Pedras. Foto: Vistoria técnica da Cáritas, 2019.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_44.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Casa construída com alvenarias autoportantes de tijolos maciços na
            comunidade de Pedras, tendo à esquerda da imagem uma parte ampliada
            com alvenaria autoportante de blocos de concreto. Foto: Vistoria
            técnica da Cáritas, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center pa-4">
        <v-col class="d-flex justify-center align-center">
          <v-btn
            :to="{ name: 'conteudos_tematicos_casas_tecnicas_construtivas' }"
            color="#EDBD43"
          >
            <strong>Voltar para "Técnicas Construtivas" </strong></v-btn
          >
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Casa /",
          href: "conteudos_tematicos_casas",
        },

        {
          text: "Técnicas Construtivas Tradicionais /",
          href: "conteudos_tematicos_casas_tecnicas_construtivas",
        },

        {
          text: "A estrutura",
          href: "conteudos_tematicos_casas_estrutura",
        },
      ],
    };
  },
};
</script>

<style scoped>
.section__margin-bottom {
  margin-bottom: 3em;
}

.pointer {
  cursor: pointer;
}

p {
  font-size: 0.6em;
}
@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }
  .border__image {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image::before {
    display: none;
  }

  .border__image2 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image2::before {
    display: none;
  }

  .border__image3 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image3::before {
    display: none;
  }
  .border__image4 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image4::before {
    display: none;
  }

  .border__image5 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image5::before {
    display: none;
  }
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.arvores_bg {
  background-image: url("../../assets/conteudo_genero_arvores.png");
  background-size: cover;
  width: 100%;
  height: 650px;
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_02.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
.border__image h3 {
  color: #e06919;
  font-size: 0.5em;
}

.border__image2 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_03.png");
  background-size: 100% 100%;
}

.border__image2 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image3 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image3::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg2.png");
  background-size: 100% 100%;
}

.border__image3 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image4 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image4::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg3.png");
  background-size: 100% 100%;
}

.border__image4 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image5 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image5::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg3.png");
  background-size: 100% 100%;
}

.border__image5 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
</style>
